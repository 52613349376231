
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AdministratorNavigationVue from "@/components/administrator/navigation.vue";
import AdministratorDashboardNavigationVue from "@/components/administrator/dashboard/navigation.vue";
import AppAdministratorDashboardStatisticsMatchesIndexVue from "@/views/app/administrator/dashboard/statistics/matches/index.vue";
import AppAdministratorDashboardStatisticsTrainingsIndexVue from "@/views/app/administrator/dashboard/statistics/trainings/index.vue";
import AppAdministratorDashboardRootUserInformationIndexVue from "@/views/app/administrator/dashboard/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    AdministratorNavigationVue,
    AdministratorDashboardNavigationVue,
    AppAdministratorDashboardStatisticsMatchesIndexVue,
    AppAdministratorDashboardStatisticsTrainingsIndexVue,
    AppAdministratorDashboardRootUserInformationIndexVue,
  },
})
export default class AppAdministratorDashboardStatisticsIndexVue extends Vue {}
